import Web3 from "web3";
import getEnvironment from "../config/config";

const fetchNonce = async (account) => {
  const env = getEnvironment();
  const resp = await fetch(env.backendURL + "web3/nonce", {
    method: "POST",
    body: account,
  });

  return resp.text();
};

const fetchMail = async (sign, account, mail) => {
  const env = getEnvironment();
  const resp = await fetch(env.backendURL + "ifnft/register", {
    method: "POST",
    body: JSON.stringify({
      key: account,
      signature: sign,
      mail: mail,
    }),
  });

  console.log(resp.status);

  return resp.status === 200;
};

const sendMailAdress = async (mail) => {
  if (window.ethereum) {
    await window.ethereum.request({ method: "eth_requestAccounts" });
    window.web3 = new Web3(window.ethereum);
  } else return false;

  let account;

  try {
    account = (await window.web3.eth.requestAccounts())[0];
  } catch (e) {
    console.log(e);

    return false;
  }

  const nonce = await fetchNonce(account);

  console.log(nonce);

  let sign;
  try {
    sign = await window.web3.eth.personal.sign(nonce, account, "hi", "asdsdsa");
  } catch (e) {
    console.log(e);
    return false;
  }

  return await fetchMail(sign, account, mail);
};

export default sendMailAdress;
