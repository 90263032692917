import "./App.css";
import ifLogo from "./assets/ifLogo.png";
import Faq from "react-faq-component";
import EcotreeModal from "./ecotreeModal/ecotreeModal";

const data = {
  title: "",
  rows: [
    {
      title: "What are NFTs?",
      content: `NFT stands for "Non-Fungible Token". Unlike Bitcoin or other cryptocurrencies, NFTs are unique and individual. They are digital certificates that represent the ownership rights to a specific digital asset, be it a picture, video, piece of music or even a virtual property.`,
    },
    {
      title: "How do NFTs work?",
      content:
        "NFTs use blockchain technology to guarantee their uniqueness and authenticity. A blockchain is a kind of decentralised database that tracks all transactions transparently and securely. Each NFT has a unique identifier that distinguishes it from others. This makes it possible to track exactly who became the owner of a particular NFT and when.",
    },
    {
      title: "Why are NFTs so exciting?",
      content: `NFTs enable artists, creators and collectors to create, sell and own digital works like never before. They create a new kind of appreciation for digital art and content. Owning an NFT means that you have authentic ownership of a unique digital asset - comparable to an autographed painting or a rare collectible.`,
    },
    {
      title: "How can I purchase NFTs?",
      content: `To buy NFTs, you first need a crypto wallet where you can store cryptocurrencies like Ethereum. Most NFTs are bought with Ethereum. Once you have Ethereum, you can search for NFTs that interest you on NFT marketplaces like OpenSea, Rarible or Foundation. There you can exchange your Ether for an NFT.`,
    },
    {
      title: "What are our NFTs?",
      content: `With our NFTs, we want to bring together regional artists, the green aspect and new technologies. With the NFTs, a tree is planted, a donation is made to - and the respective artist is supported. At the Impact Festival, we offer those interested an introduction to the world of crypto, blockchain and NFT.`,
    },
    {
      title: "On which blockchain are our NFTs hosted?",
      content:
        "We minted our NFTs on the Polygon blockchain to reduce the carbon footprint of this project.",
    },
    {
      title: "What is an NFT wallet and how do I create one?",
      content: `An NFT wallet is a digital wallet that allows you to store and manage NFTs securely. You can create an NFT wallet by using a wallet app or a web wallet on a supported blockchain.`,
    },
  ],
};

const styles = {
  bgColor: "#ffffff00",
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: "white",
  arrowColor: "#4cd9b0",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

function App() {
  return (
    <div className="App">
      <div className="AppContent">
        <div className="tile">
          <div className="AppHeader">
            <h2>NFT @ IMPACT FESTIVAL 23</h2>
            <p>
              With our NFT collection we want to bring together regional
              artists, biodiversity and new technologies. With each NFT a tree
              is planted, a donation is made to the animal welfare project
              Monkeyland by the organisation Live&Learn and the artist is
              supported. At the Impact Festival we offer those who are
              interested an introduction to the world of crypto, blockchain and
              NFTs.
            </p>
          </div>
        </div>
        <div className="tile">
          <div className="BuyNFT">
            <h3>NFT Collection</h3>
            <p>
              Buy one of our NFTs at the Impact Festival or online. They are
              available for 365 days from the start of the festival as long as
              supplies last on Opensea.
            </p>
            <div className="Content">
              <a href="https://opensea.io/de-DE/collection/impact-nfts-neosfer">
                <div className="button">NFT Collection</div>
              </a>
            </div>
          </div>
        </div>
        <div className="tile">
          <div className="Projects">
            <h3>Supported Projects</h3>
            <p>
              We forward 50% of the sales proceeds directly to two sustainable
              projects:
            </p>
            <div className="Content">
              <div className="Project">
                <a href="https://ecotree.green/">ecotree</a>
                <p>
                  ecotree supports reforestation projects abroad as well as
                  regional projects in the Harz region. With our NFTs, you can
                  purchase a tree sponsorship or an ownership tree and thus
                  support sustainable forestry.
                </p>
                <p className="percent">~ 20%</p>
                <EcotreeModal></EcotreeModal>
              </div>

              <div className="Project">
                <a href="https://foundation.liveandlearn.de/affenschutzschutzprojekt/">
                  Monkeyland
                </a>
                <p>
                  Monkeyland is a primate sanctuary in Plettenberg Bay & in the
                  Dolphin Coast region of KwaZulu-Natal. It is home to primates
                  of all species that have previously lived in laboratories,
                  circuses, zoos or private homes in captivity. The rescue of
                  monkeys from unsuitable living conditions and their
                  appropriate care is at the centre of the sanctuary. The money
                  is primarily needed for medical care and feeding of the
                  animals.
                </p>
                <p className="percent">~ 30%</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tile">
          <div className="Artists">
            <h3>Artists</h3>
            <p>
              We collaborated with artists from our region, who created unique
              pieces of art for our NFTs. They will receive 50% of the proceeds
              from the sale as well.
            </p>
            <div className="Content">
              <div className="Artist">
                <a href="https://verenakandler.com/">Verena Kandler</a>
              </div>
              <div className="Artist">
                <a href="https://www.frankundsteff.de/">Frank & Steff</a>
              </div>
              <div className="Artist">
                <a href="https://supereliot.de/">ELIOT | the super</a>
              </div>
              <div className="Artist">
                <a href="https://marinaackar.de/">Marina Ackar</a>
              </div>
            </div>
          </div>
        </div>
        <div className="tile">
          <div className="Faq">
            <h3>FAQ</h3>
            <Faq data={data} styles={styles} config={config} />
          </div>
        </div>
        <div className="tile">
          <div className="AppFooter">
            <div className="FooterContent">
              <a href="https://impact-festival.earth/de/">
                <img alt="ifLogo" src={ifLogo}></img>
              </a>
              <a href="https://impact-festival.earth/legal-information/">
                Legal information
              </a>
              <a href="https://impact-festival.earth/imprint/">Imprint</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
