const getEnvironment = () => {
  let backendURL = "";

  switch (window.origin) {
    case "https://nft23.impact-festival.earth":
      backendURL =
        "https://us-central1-decentraland-372912.cloudfunctions.net/api/";
      break;
    default:
      backendURL =
        "https://us-central1-decentraland-dev.cloudfunctions.net/api/";
  }

  return {
    backendURL: backendURL,
  };
};

export default getEnvironment;
