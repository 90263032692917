import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./ecotreeModal.css";
import sendMailAdress from "../api/api";
import { useState } from "react";

function EcotreeModal() {
  const [statusMsg, setStatusMsg] = useState("");
  const [isSucceeded, setIsSucceeded] = useState(false);
  return (
    <Popup
      trigger={<div className="button"> Claim your certificate </div>}
      modal
      nested
    >
      {(close) => (
        <div className="modal">
          <button className="close" onClick={close}>
            &times;
          </button>
          <div className="header"> Claim your certificate </div>
          <div className="modalBody">
            <div className="content">
              Thank you for purchasing our ImpactNFTs!
              <br></br>
              <br></br>
              To give you access to Ecotree and your tree sponsorship (standard
              NFT) or tree ownership (premium NFT), we need your email address.
              <br></br>
              As soon as everything is ready, you will get a notification with
              your access data.
            </div>
            {isSucceeded ? (
              <p className="success">
                Thank you for submitting your mail address. You will recieve the
                certificate for your Impact NFTs owned by this account in the
                following days!
              </p>
            ) : window.ethereum === undefined ? (
              <p className="warning">
                No Ethereum wallet provider found! Please add one to your
                browser to continue.
              </p>
            ) : (
              <form
                onSubmit={async (o) => {
                  o.preventDefault();

                  if (await sendMailAdress(o.target["mail"].value)) {
                    setIsSucceeded(true);
                  } else {
                    setStatusMsg(
                      "Error while transfering data! Please try again or contact us."
                    );
                  }
                }}
              >
                <input
                  type="text"
                  id="mail"
                  name="mail"
                  className="mail"
                  placeholder="E-Mail"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  title="Invalid mail format"
                  required
                ></input>
                <div className="checkboxDatatransfer">
                  <input type="checkbox" id="datatransfer" required></input>
                  <label htmlFor="datatransfer">
                    I consent to my Wallet address being stored together with my
                    e-mail address by{" "}
                    <a className="linkInText" href="https://neosfer.de">
                      neosfer GmbH
                    </a>{" "}
                    and to my e-mail address being transmitted to{" "}
                    <a
                      className="linkInText"
                      href="https://ecotree.green/en/companies/csr"
                    >
                      ecotree
                    </a>{" "}
                    for contact purposes.<br></br> I have the right to revoke
                    this consent at any time without affecting the lawfulness of
                    the processing carried out on the basis of the consent until
                    the revocation. The revocation is to be addressed to:
                    datenschutz@neosfer.com
                  </label>
                </div>
                <input
                  type="submit"
                  value="Connect wallet"
                  className="button"
                ></input>
              </form>
            )}
            <p className="warning" hidden={statusMsg.length === 0}>
              {statusMsg}
            </p>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default EcotreeModal;
